import React from "react"
import { useTranslation } from "react-i18next"

import { Container } from "../components/container"
import SEO from "../components/seo"

const ImpressumPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title="Impressum" />
      <Container className="terms">
        <h1 className="text-center">{t("impressumPage.pageTitle")}</h1>

        <section>
          <h3 style={{ margin: "30px 0" }}>
            {t("impressumPage.pageSubTitle")}
          </h3>
          <p>
            <strong>Nutritionpro GmbH</strong>
            <br />
            c/o HQ (3rd floor) <br />
            Feringastr. 6<br />
            D-85774 Unterföhring
          </p>
          <p>
            <strong>{t("impressumPage.phone")}</strong> +49 176 84360809
          </p>
          <p>
            <strong>{t("impressumPage.email")}</strong> info@nutritionpro.de
          </p>
          <p>
            <strong>{t("impressumPage.registeredOffice")}</strong> Dingolfinger
            Straße 15, 81673 München{t("impressumPage.country")}
          </p>
          <p>
            <strong>{t("impressumPage.commercialRegister")}</strong> München HRB
            275560
          </p>
          <p>
            <strong>{t("impressumPage.managingDirector")}</strong> Nurlan
            Abdrassilov
          </p>
          <h3 style={{ margin: "30px 0" }}>
            {t("impressumPage.pageSubTitle2")}
          </h3>
          <p>
            <strong>Nutritionpro GmbH</strong>
            <br />
            c/o HQ (3rd floor) <br />
            Feringastr. 6<br />
            D-85774 Unterföhring
          </p>
          <h3 style={{ margin: "30px 0" }}>
            {t("impressumPage.pageSubTitle3")}
          </h3>
          <p>Nurlan Abdrassilov</p>
        </section>
      </Container>
    </>
  )
}

export default ImpressumPage
